:root {
  --orange: hsl(25, 97%, 53%);
  --white: hsl(0, 0%, 100%);
  --black: #141519;
  --light-grey: hsl(217, 12%, 63%);
  --medium-grey: hsl(216, 12%, 54%);
  --dark-blue: hsl(213, 19%, 18%);
  --medium-dark-blue: #171a1f;
  --very-dark-blue: hsl(216, 12%, 8%);
}
