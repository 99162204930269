@use "../util/" as *;

.thank-you-component {
  display: none;
  align-items: center;
  flex-direction: column;
  @include breakpoint-down(small) {
    margin: rem(20);
    height: rem(450);
  }
  &__img {
    margin-bottom: rem(25);
  }
  &__rating {
    color: var(--orange);
    background-color: var(--dark-blue);
    border-radius: rem(100);
    padding: rem(10) rem(18);
    margin-bottom: rem(30);
  }
  &__description {
    text-align: center;
  }
}
.show {
  display: flex;
}
