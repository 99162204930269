@use "../util/" as *;
.hide {
  display: none;
}
.component {
  width: rem(412);
  height: rem(416);
  background-image: linear-gradient(
    180deg,
    var(--dark-blue),
    var(--medium-dark-blue)
  );
  border-radius: rem(25);
  padding: rem(32);

  @include breakpoint-down(small) {
    margin: rem(20);
    height: rem(450);
  }
  &__rating {
    margin-bottom: rem(25);
    .star {
      width: rem(20);
      height: rem(20);
      color: var(--orange);
    }
  }
  &__header {
    color: var(--white);
    font-size: rem(28);
    margin-bottom: rem(16);
  }
  &__description {
    color: var(--light-grey);
    font-size: rem(15);
    margin-bottom: rem(26);
    line-height: 1.7;
  }
  &__numbers {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin-bottom: rem(26);
  }
  &__rating,
  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(50);
    height: rem(50);
    border-radius: rem(100);
    background-color: var(--dark-blue);
  }
  &__number {
    color: var(--light-grey);

    &:hover {
      background-color: var(--light-grey);
      color: var(--white);
    }
    &:focus {
      background-color: var(--orange);
      color: var(--white);
    }
  }
}
