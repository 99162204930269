:root {
  --orange: hsl(25, 97%, 53%);
  --white: hsl(0, 0%, 100%);
  --black: #141519;
  --light-grey: hsl(217, 12%, 63%);
  --medium-grey: hsl(216, 12%, 54%);
  --dark-blue: hsl(213, 19%, 18%);
  --medium-dark-blue: #171a1f;
  --very-dark-blue: hsl(216, 12%, 8%);
}

:root {
  --font-overpass: "Overpass", sans-serif;
}

body {
  font-family: var(--font-overpass);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  font-weight: 700;
  line-height: 1.1;
  margin-top: 0;
}

p {
  margin-top: 0;
  font-size: rem(15);
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.button {
  display: block;
  width: 100%;
  padding: 1.0625rem;
  color: var(--white);
  text-transform: uppercase;
  background-color: var(--orange);
  cursor: pointer;
  border-radius: 6.25rem;
  transition: all 0.2s;
  letter-spacing: 0.125rem;
}
.button:link, .button:visited {
  text-decoration: none;
  padding: 3px;
}
.button:hover {
  background-color: var(--white);
  color: var(--orange);
}

.hide {
  display: none;
}

.component {
  width: 25.75rem;
  height: 26rem;
  background-image: linear-gradient(180deg, var(--dark-blue), var(--medium-dark-blue));
  border-radius: 1.5625rem;
  padding: 2rem;
}
@media (max-width: 39.9375em) {
  .component {
    margin: 1.25rem;
    height: 28.125rem;
  }
}
.component__rating {
  margin-bottom: 1.5625rem;
}
.component__rating .star {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--orange);
}
.component__header {
  color: var(--white);
  font-size: 1.75rem;
  margin-bottom: 1rem;
}
.component__description {
  color: var(--light-grey);
  font-size: 0.9375rem;
  margin-bottom: 1.625rem;
  line-height: 1.7;
}
.component__numbers {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-bottom: 1.625rem;
}
.component__rating, .component__number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 6.25rem;
  background-color: var(--dark-blue);
}
.component__number {
  color: var(--light-grey);
}
.component__number:hover {
  background-color: var(--light-grey);
  color: var(--white);
}
.component__number:focus {
  background-color: var(--orange);
  color: var(--white);
}

.thank-you-component {
  display: none;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 39.9375em) {
  .thank-you-component {
    margin: 1.25rem;
    height: 28.125rem;
  }
}
.thank-you-component__img {
  margin-bottom: 1.5625rem;
}
.thank-you-component__rating {
  color: var(--orange);
  background-color: var(--dark-blue);
  border-radius: 6.25rem;
  padding: 0.625rem 1.125rem;
  margin-bottom: 1.875rem;
}
.thank-you-component__description {
  text-align: center;
}

.show {
  display: flex;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--black);
}