@use "../util/" as *;
.button {
  display: block;
  width: 100%;
  padding: rem(17);
  color: var(--white);
  text-transform: uppercase;
  background-color: var(--orange);
  cursor: pointer;
  border-radius: rem(100);
  transition: all 0.2s;
  letter-spacing: rem(2);
  &:link,
  &:visited {
    text-decoration: none;
    padding: 3px;
  }

  &:hover {
    background-color: var(--white);
    color: var(--orange);
  }
}
